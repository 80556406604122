import { render } from "./AudienceList.vue?vue&type=template&id=76e6d824&scoped=true"
import script from "./AudienceList.vue?vue&type=script&lang=ts"
export * from "./AudienceList.vue?vue&type=script&lang=ts"

import "./AudienceList.vue?vue&type=style&index=0&id=76e6d824&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-76e6d824"

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTh,QTd,QBtn,QTooltip,QChip,QSpace});
