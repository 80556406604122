<template>
  <q-select
    :disable="disable"
    dense
    outlined
    v-model="value.campaign_names"
    :options="options"
    :label="$t('label.audience.campaign')"
    color="primary"
    options-selected-class="primary"
    style="width: 30%"
    option-value="_id"
    emit-value
    map-options
    multiple
    class="q-mb-sm"
  >
    <template v-if="value.campaign_names && value.campaign_names.length > 0" v-slot:append>
      <q-icon name="cancel" @click.stop="onClearOptionChange" class="cursor-pointer" />
    </template>
    <template v-slot:option="scope">
      <q-expansion-item
        dense
        expand-separator
        group="somegroup_campaign"
        :default-opened="hasChild(scope)"
        header-class="text-weight-bold"
        :label="scope.opt.label"
        @click="selectedCampaign(scope.opt, scope.opt.type)"
      >
        <template v-for="child in scope.opt.children" :key="child._id">
          <q-item dense clickable v-ripple :class="{ 'bg-light-blue-1': isSelected(child._id) }">
            <q-item-section>
              <q-checkbox
                v-model="selectedCampaignIds"
                :val="child._id"
                @click="selectedCampaign(child, scope.opt.type)"
              >
                <q-item-label class="q-ml-md">{{ child.title }}</q-item-label></q-checkbox
              >
            </q-item-section>
          </q-item>
        </template>
      </q-expansion-item>
    </template>
  </q-select>
</template>

<script lang="ts">
import { maska } from 'maska'
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IAudience } from '@/utils/types'
import { CAMPAIGN_TYPE } from '@/utils/constants'
import AudienceMixin from '../mixins/AudienceMixin.vue'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class CampaignDropdownSelector extends mixins(AudienceMixin) {
  @Prop({})
  modelValue!: IAudience

  @Prop({})
  disable!: boolean

  selectedCampaignIds: string[] = []

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get value() {
    return this.modelValue
  }

  set value(value: IAudience) {
    this.$emit('update:modelValue', value)
  }

  get options() {
    const dataOptions = [
      {
        label: this.$t('menu.inital_message_campaigns'),
        children: [],
        type: CAMPAIGN_TYPE.INITIAL,
      },
      {
        label: this.$t('menu.push_mespage_campaigns'),
        children: [],
        type: CAMPAIGN_TYPE.PUSH,
      },
    ]
    return dataOptions
  }

  selectedCampaign(item, type) {
    if (!item._id) {
      return
    }

    if (this.value.campaign_ids) {
      const index = this.value.campaign_ids.findIndex(({ _id }) => _id === item._id)
      if (index !== -1) {
        this.value.campaign_ids.splice(index, 1)
      } else {
        this.value.campaign_ids.push({
          _id: item._id,
          title: item.title,
          type,
        })
      }
      const titles = this.value.campaign_ids.map(function (item) {
        return item.title
      })

      this.value.campaign_names = titles.join(', ')
    }
  }

  hasChild(scope) {
    if (scope.opt.children && this.value.campaign_ids && this.value.campaign_ids.length > 0) {
      const id = this.value.campaign_ids[0]._id
      return scope.opt.children.some((c) => c._id === id)
    }
  }

  isSelected(id) {
    if (this.value.campaign_ids) {
      return this.value.campaign_ids.some((c) => c._id === id)
    }

    return false
  }

  async loadCampaign() {
    if (!this.selectedAppId) {
      return
    }

    this.options[0].children = this.initials
    this.options[1].children = this.pushs
    if (this.value.campaign_ids) {
      this.value.campaign_ids.forEach((element) => {
        this.selectedCampaignIds.push(element._id ?? '')
      })
    }
  }

  onClearOptionChange() {
    this.value.campaign_ids = []
    this.value.campaign_names = ''
    this.selectedCampaignIds = []
  }
}
</script>
