<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 400px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title> {{ modalTitle }} </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <div class="max-height-dialog">
            <CLoading :loading="loading" />
            <div class="q-pb-md text-grey-9 text-bold">
              {{ $t('label.audience.fork_description') }}
            </div>
            <label class="text-grey-9 text-bold">{{ $t('label.audience.audience_name') }} </label>
            <q-input
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || requiredRule.audienceName]"
              outlined
              v-model="forkName"
              dense
              :placeholder="$t('label.audience.audience_name')"
            />

            <label class="text-grey-9 text-bold">{{ $t('label.audience.fork_number') }} </label>
            <q-input
              v-model="forkNumber"
              dense
              outlined
              type="number"
              class="text-h6"
              min="1"
              :rules="[
                (val) => (val !== null && val !== '') || requiredRule.frequencyCap,
                (val) => val > 0 || requiredRule.minFrequencyCap,
              ]"
            />
            <div class="row" v-if="errorMessage !== ''">
              <div class="text-red">{{ errorMessage }}</div>
            </div>
          </div>
          <div class="row no-wrap items-center q-mt-md">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn no-caps flat class="btn-cancel" @click="onCancel">{{ $t('cancel') }}</q-btn>
              <q-btn no-caps class="btn-save" @click="onSubmit"> {{ $t('save') }} </q-btn>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ACTION_AUDIENCE } from '@/store/actions'
import { IAudience } from '@/utils/types'
import CLoading from '@/components/common/ui/CLoading.vue'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: {
    CLoading,
  },
  directives: { maska },
  emits: ['update:saveModal'],
})
export default class ForkAudienceFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IAudience

  forkNumber = 0
  forkName = ''
  loading = false
  errorMessage = ''

  get requiredRule() {
    const requiredRule = {
      audienceName: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.audience.audience_name'),
      }),
      frequencyCap: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.audience.fork_number'),
      }),
      minFrequencyCap: this.$t('validate.must_be_greater_than_0'),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.$t('label.audience.fork_audience')
  }

  onSubmit() {
    this.errorMessage = ''
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        if (this.data.audience_size && this.forkNumber > this.data.audience_size) {
          this.errorMessage = this.$t('validate.fork_number_must_be_greater_than_audience_size')
          return
        } else if (this.forkNumber > 100) {
          this.errorMessage = this.$t('messages.fork_num_max')
          return
        }
        this.loading = true
        await this.doSave()
        this.loading = false
        this.visible = false
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let message = null

    message = await dispatch(ACTION_AUDIENCE.FORK, {
      audience: this.data,
      fork_number: this.forkNumber,
      fork_name: this.forkName,
    })
    if (message) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })

      this.$emit('update:saveModal')
    }
  }

  created() {
    if (this.data) {
      this.forkName = this.data.title ?? ''
    }
  }
}
</script>
